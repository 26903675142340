import cn from "clsx";
import React, {
    ComponentPropsWithoutRef,
    createElement,
    ForwardedRef,
    forwardRef,
    HTMLAttributes,
    JSX,
} from "react";
import { LoadingSpinner } from "../loading-spinner";
import styles from "./Button.module.scss";

type ValidHTMLTags = "button" | "a" | "label" | "span";

type ReactComponent = Exclude<React.ElementType, keyof JSX.IntrinsicElements>;

type ValidTags = ValidHTMLTags | ReactComponent;

export type ButtonVariant = "primary" | "secondary" | "quiet" | "inverse";

export type ButtonProps<T extends ValidTags> = ComponentPropsWithoutRef<T> &
    HTMLAttributes<HTMLOrSVGElement> & {
        /**
         * Icon displayed to the left of the text
         */
        adornLeft?: React.ReactNode;

        /**
         * Icon displayed to the right of the text
         */
        adornRight?: React.ReactNode;

        /**
         * Text to put in the button
         */
        children: React.ReactNode;

        /**
         * Is the button loading?
         * @default false
         */
        loading?: boolean;

        /**
         * Color palette for the specified `variant`
         */
        palette?: string;

        /**
         * Size of the button
         * @default standard
         */
        size?: "standard" | "small";

        /**
         * Design variant of the button
         * @default primary
         */
        variant?: ButtonVariant;

        /**
         * Alternate JSX element to use for the button
         * @default button
         */
        tag?: T | ValidTags;
    };

const DEFAULT_TAG = "button" as const;

export const InnerButton = <T extends ValidTags = typeof DEFAULT_TAG>(
    {
        adornLeft,
        adornRight,
        children,
        loading,
        palette,
        size = "standard",
        tag = DEFAULT_TAG,
        variant = "primary",
        className,
        ...rest
    }: ButtonProps<T>,
    ref: React.ForwardedRef<HTMLOrSVGElement>,
) => {
    const disabled =
        (rest as JSX.IntrinsicElements["button"]).disabled ?? false;
    const buttonClassName = cn(
        styles.button,
        styles[`button-${size}`],
        styles[`${variant}`],
        styles[`${variant}-${size}`],
        `button-${variant}-fallback-palette`,
        { [`button-${variant}-${palette}-palette`]: palette },
        className,
    );

    // Show the loading indicator on the left by default. If there's no left adornment
    // but there is an adornment on the right then show it on the right.
    // The loading indicator always replaces the existing adornment.
    const left =
        (adornLeft && loading) || (!adornRight && loading) ? (
            <LoadingSpinner />
        ) : (
            adornLeft
        );
    const right =
        adornRight && !adornLeft && loading ? <LoadingSpinner /> : adornRight;

    return (
        <>
            {createElement(
                tag,
                {
                    ...rest,
                    className: buttonClassName,
                    ["data-loading"]: loading || undefined,
                    disabled: disabled || loading,
                    ref,
                },
                <>
                    {left && <span className={styles.icon}>{left}</span>}
                    {children}
                    {right && <span className={styles.icon}>{right}</span>}
                </>,
            )}
            {loading && (
                <span
                    aria-live="polite"
                    className={styles["visually-hidden"]}
                    role="status"
                >
                    Now loading
                </span>
            )}
        </>
    );
};

export const Button = forwardRef(InnerButton) as <
    T extends ValidTags = typeof DEFAULT_TAG,
>(
    props: ButtonProps<T> & { ref?: ForwardedRef<any> },
) => ReturnType<typeof InnerButton>;
