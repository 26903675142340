import React, { PropsWithChildren } from "react";
import { MemoryRouter } from "react-router-dom";

export type AuthRouterState = {
    email?: string;
    hasPassword?: boolean;
    tourCode?: string;
};

export const AuthRouter: React.FC<PropsWithChildren> = ({ children }) => {
    return <MemoryRouter>{children}</MemoryRouter>;
};
