import { Item } from "./ga4-ecommerce-event-types";

export type Ga4PricedItem = Item & {
    item_id: string;
    item_name: string;
    price: number;
    salePrice?: number;
    discount: number;
    activityLevel?: string;
    classLevel?: string;
    onSale?: boolean;
    groupTrip?: boolean;
    hasExtension?: boolean;
    prodStatus?: string;
    promoCodeApplied?: string;
    room_reservation_name?: string;
    quantity: number;
};

/**
 * In order to send ecommerce items to GA4 correctly, we need to group all of our line items by price and item_id.
 * This function reduces a list of items by checking if we had an item with the same price and item_id previously and
 * if so, we increment the quantity of that item, and if not, we add the item to the list.
 * @param items The ecommerce items to be sent to GA4
 * @param commonItemProps The common properties for all items
 * @returns An array of items with the common properties added that are grouped by price and item_id
 */
export const getGa4Items = (
    items: Ga4PricedItem[],
    commonItemProps: {
        item_brand: string | undefined;
        /** customerType: Group or Indies */
        item_category3?: string;
        tourCode: string;
    },
) => {
    return items.reduce((acc, item) => {
        const existingItem = acc.find(
            (i) => i.price === item.price && i.item_id === item.item_id,
        );
        if (existingItem) {
            existingItem.quantity++;
        } else {
            acc.push({
                ...item,
                ...commonItemProps,
            });
        }
        return acc;
    }, new Array<Ga4PricedItem>());
};
