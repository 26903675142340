import Script from "next/script";
import React from "react";

type Props = {
    /**
     * GTM account id
     */
    gtmId: string;
};

/**
 * Loads the GTM script
 * @param gtmId The GTM account id
 */
export const GTMScript: React.FC<Props> = ({ gtmId }) => {
    return (
        <Script
            id={gtmId}
            dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l] = w[l] || [];  w[l].push({"gtm.start":
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${gtmId}' );`,
            }}
        />
    );
};
