export const getLoginSuccessPath = (
    onLoginSuccessPath?: string,
): string | undefined => {
    if (onLoginSuccessPath) {
        return onLoginSuccessPath;
    } else if (typeof window !== "undefined") {
        const urlParams = new URLSearchParams(window.location.search);
        const next = urlParams.get("next");
        return next ? next : undefined;
    }
};
