import { gtmSendEvent } from "@wojo/analytics";
import { useEffect } from "react";
import { useSigninCustomerWithMagicLinkMutation } from "../../../generated/graphql";
import { AuthPaths } from "../../components/router/AuthPaths";
import {
    LoginOrigin,
    SetViewArgs,
} from "../context/internal-auth-context-provider.types";

export const useMagicLinkToken = ({
    setView,
    signin,
    loginOrigin,
}: {
    setView: (args: SetViewArgs) => void;
    signin: () => Promise<void>;
    loginOrigin?: LoginOrigin;
}) => {
    const [signInWithToken] = useSigninCustomerWithMagicLinkMutation();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        const isResetPassword = urlParams.get("auth") === "reset-password";

        if (token) {
            signInWithToken({ variables: { token } })
                .then(async ({ data }) => {
                    const magicLinkAuth = data?.signinCustomerWithMagicLink;
                    if (magicLinkAuth?.__typename !== "SigninResult") {
                        setView({
                            view: isResetPassword
                                ? AuthPaths.ResetPasswordLinkExpired
                                : AuthPaths.MagicLinkExpired,
                        });
                        return;
                    }

                    await signin();

                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            eventCategory: "login",
                            eventAction: `${loginOrigin} no password account`,
                            eventLabel: "automatic access link SUCCEED",
                            ga4_object: "LOGIN",
                            ga4_action: "LOGGED_IN",
                            loginId: `${loginOrigin}NoPasswordAccount`,
                            magicLink: "yes",
                        },
                    });

                    setView({
                        view: isResetPassword
                            ? AuthPaths.ResetPassword
                            : AuthPaths.LoginWithMagicLinkSuccess,
                        routerState: {
                            hasPassword: magicLinkAuth.hasPassword ?? false,
                        },
                    });
                })
                .catch(() => {
                    setView({
                        view: isResetPassword
                            ? AuthPaths.ResetPasswordLinkExpired
                            : AuthPaths.MagicLinkExpired,
                    });
                })
                .finally(() => {
                    // Remove the "token" query param so navigation does not trigger login again
                    urlParams.delete("token");
                    urlParams.delete("auth");
                    const url = new URL(window.location.href);
                    url.search = urlParams.toString();
                    window.history.replaceState(null, "", url.toString());
                });
        }
    }, [signInWithToken, setView, signin, loginOrigin]);
};
