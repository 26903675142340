/**
 * Ascends the DOM from a given element and returns the first data-ga4-object attribute found.
 * @param element The DOM element to start searching from.
 */
export const getGa4Object = (element: HTMLElement | null): string | null => {
    for (
        ;
        element && element !== document.body;
        element = element.parentElement
    ) {
        const dataGa4Object = element.getAttribute("data-ga4-object");
        if (dataGa4Object) {
            return dataGa4Object;
        }
    }
    return null;
};
