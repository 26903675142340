import { useFragment, useSuspenseQuery } from "@apollo/client";
import Cookies from "js-cookie";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
    BusinessCode,
    AuthCustomerFragmentDoc,
    CustomerMeDocument,
    CustomerMeQuery,
    CustomerMeQueryVariables,
    useSignoutMutation,
} from "../../../generated/graphql";
import { AuthPaths } from "../../components/router/AuthPaths";
import { getLoginSuccessPath } from "../hooks/get-login-success-path";
import { useBroadcastChannel } from "../hooks/use-broadcast-channel";
import { useMagicLinkToken } from "../hooks/use-magic-link-token";
import {
    DisplayType,
    InternalAuthContextState,
    InternalAuthContextValue,
    SetViewArgs,
} from "./internal-auth-context-provider.types";
import { WebSource } from "./types";
const InternalAuthContext = createContext<InternalAuthContextState>({
    authGuardedPaths: [],
    smsOptInDefault: false,
});

export const InternalAuthContextProvider: React.FC<
    InternalAuthContextValue
> = ({ children, ...values }) => {
    const navigate = useNavigate();
    const [signoutMutation] = useSignoutMutation();
    const {
        data,
        refetch: refetchCustomer,
        networkStatus,
    } = useSuspenseQuery<CustomerMeQuery, CustomerMeQueryVariables>(
        CustomerMeDocument,
        {
            variables: {
                businessCode: process.env
                    .NEXT_PUBLIC_BUSINESS_CODE as BusinessCode,
            },
            skip: !values.isLoggedIn,
            context: { fetchOptions: { cache: "no-store" } },
        },
    );

    const customerFragment = useFragment({
        fragment: AuthCustomerFragmentDoc,
        fragmentName: "AuthCustomer",
        from: {
            __typename: "Customer",
            id: data?.me?.__typename === "Customer" ? data.me.customerId : "",
        },
    });
    const [state, setState] = useState<
        Omit<InternalAuthContextState, "setView">
    >({
        isLoggedIn: values.isLoggedIn,
        webSource: values.webSource,
        omitPaths: values.omitPaths,
        smsOptInDefault: values.smsOptInDefault,
        authGuardedPaths: values.authGuardedPaths,
        isModalOpen: false,
        loginOrigin: "login",
        displayType: "MODAL",
    });

    /* *** start state helper methods *** */
    const setIsModalOpen = useCallback(
        (open: boolean) => {
            setState((prevState) => ({ ...prevState, isModalOpen: open }));
        },
        [setState],
    );
    const setDisplayType = useCallback(
        (displayType: DisplayType) => {
            setState((prevState) => ({ ...prevState, displayType }));
        },
        [setState],
    );
    const setIsLoggedIn = useCallback(
        (isLoggedIn: boolean) => {
            setState((prevState) => ({ ...prevState, isLoggedIn }));
        },
        [setState],
    );
    const setWebSource = useCallback(
        (webSource: WebSource) => {
            setState((prevState) => ({ ...prevState, webSource }));
        },
        [setState],
    );
    const setLastFilledField = useCallback(
        (lastFilledField: string) => {
            setState((prevState) => ({ ...prevState, lastFilledField }));
        },
        [setState],
    );

    const setView = useCallback(
        ({
            view,
            routerState,
            loginOrigin,
            onLoginSuccessPath,
            onLoginComplete,
            hideCTAs,
        }: SetViewArgs) => {
            setState((prevState) => ({
                ...prevState,
                isModalOpen: !(state.displayType === "FULL_PAGE"),
                loginOrigin: loginOrigin || prevState.loginOrigin,
                onLoginSuccessPath: getLoginSuccessPath(
                    onLoginSuccessPath || prevState.onLoginSuccessPath,
                ),
                onLoginComplete: onLoginComplete || prevState.onLoginComplete,
                hideCTAs: hideCTAs || prevState.hideCTAs,
            }));
            setIsModalOpen(true);
            navigate(view, {
                state: routerState,
            });
        },
        [navigate, setIsModalOpen, state.displayType],
    );

    /* *** end state helper methods *** */

    const signout = async () => {
        await signoutMutation();
        await refetchCustomer(); // update Apollo cache
        setIsLoggedIn(false);

        const pathname = window.location.pathname;
        const shouldRedirect = values.authGuardedPaths.find(
            (url: string) =>
                pathname.startsWith(url) &&
                !values.omitPaths?.some((item) => pathname.startsWith(item)),
        );

        // Redirect to main page with next param if user is on a guarded page.
        // Other browser tabs will also be redirected when reloaded through the broadcast channel.
        if (pathname !== "/_error" && shouldRedirect) {
            window.location.replace(`/?auth=login&next=${pathname}`);
        } else {
            window.location.reload();
        }
    };

    const signin = useCallback(async () => {
        const result = await refetchCustomer();

        if (result.data?.me?.__typename !== "Customer") {
            return;
        }
        setIsLoggedIn(true);

        Cookies.set("cid", result.data.me.customerId, { expires: 365 });

        state.onLoginComplete?.(result.data.me.customerId);
        // prevent callback from calling more than once if it updates user upon login (e.g. wishlist)
        setState((prevState) => ({
            ...prevState,
            onLoginComplete: undefined,
        }));
    }, [refetchCustomer, setIsLoggedIn, state]);

    useMagicLinkToken({ setView, signin, loginOrigin: state.loginOrigin });
    useBroadcastChannel(!!state.isLoggedIn);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const authView = urlParams.get("auth");

        if (authView === "login") {
            setView({
                view: AuthPaths.VerifyEmail,
            });
        }
        if (authView === "create-account") {
            setView({ view: AuthPaths.CreateAccount });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <InternalAuthContext.Provider
            value={{
                ...state,
                customer: customerFragment.data,
                setView,
                setIsModalOpen,
                setDisplayType,
                setWebSource,
                setLastFilledField,
                signout,
                signin,
                refetchCustomer,
                loadingCustomer: networkStatus === 1,
            }}
        >
            {children}
        </InternalAuthContext.Provider>
    );
};

export const useInternalAuth = () => {
    const props = useContext(InternalAuthContext);
    return props;
};
