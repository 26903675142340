import cn from "clsx";
import { FC, SVGAttributes } from "react";
import styles from "./LoadingSpinner.module.scss";

export type LoadingSpinnerProps = SVGAttributes<SVGElement> & {
    /**
     * Determines the size. Accepts a valid CSS sizing unit
     * @default 1em
     */
    size?: string;
    /**
     * Determines the variant
     * @default standard
     */
    variant?: "standard" | "inverse";
    /**
     * Determines the color palatte
     */
    palette?: string;
    /**
     * @default Loading
     * Screen reader text
     */
    accessibleText?: string;
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    size = "1em",
    variant = "standard",
    accessibleText = "Loading",
    className,
    palette,
    ...svgProps
}) => {
    const spinnerClassName = cn(
        styles["loading-spinner"],
        {
            [styles[`loading-spinner--${variant}`]]: variant,
            [`loading-spinner-${variant}-${palette}-palette`]: palette,
        },
        className,
    );

    return (
        <svg
            {...svgProps}
            aria-label={accessibleText || undefined}
            aria-hidden={!accessibleText || undefined}
            className={spinnerClassName}
            fill="none"
            style={{
                ...svgProps.style,
                height: size,
                width: size,
            }}
            focusable="false"
            role="img"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            {accessibleText && <title>{accessibleText}</title>}
            <path
                className={styles["loading-spinner-container"]}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            />
            <path
                className={styles["loading-spinner-indicator"]}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.875 15.1249C8.875 15.6082 8.48325 15.9999 8 15.9999C6.68998 15.9999 5.4 15.6782 4.2434 15.0631C3.08679 14.4479 2.09895 13.5582 1.36665 12.4719C0.634351 11.3857 0.180007 10.1363 0.0435311 8.83336C-0.0929444 7.53047 0.0926255 6.214 0.583939 4.9996C0.765177 4.55163 1.27526 4.33539 1.72323 4.51663C2.17121 4.69787 2.38744 5.20795 2.2062 5.65593C1.82236 6.60467 1.67739 7.63317 1.78401 8.65105C1.89063 9.66894 2.24559 10.6451 2.8177 11.4937C3.3898 12.3423 4.16156 13.0374 5.06516 13.518C5.96875 13.9986 6.97655 14.2499 8 14.2499C8.48325 14.2499 8.875 14.6417 8.875 15.1249Z"
            />
        </svg>
    );
};
