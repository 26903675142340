import { createContext, useContext } from "react";
import { AuthRouter } from "../../components/router/AuthRouter";
import {
    InternalAuthContextProvider,
    useInternalAuth,
} from "./InternalAuthContextProvider";
import { WebSource } from "./types";

export type AuthContextValue = React.PropsWithChildren<{
    /**
     * Paths that should not show the auth modal
     */
    omitPaths?: string[];
    /**
     *  The web source of the auth context i.e. INDIES or GROUPS
     */
    webSource?: WebSource;
    /**
     * Whether the user is logged in, this is server rendered initially
     */
    isLoggedIn: boolean;
    /**
     * Paths that should be guarded by the auth context.
     * This array is also used in the middleware to redirect guarded pages on server render.
     */
    authGuardedPaths: string[];
    /**
     * Default value for create account checkbox
     */
    smsOptInDefault: boolean;
}>;

export type AuthContextState = {};

const AuthContext = createContext<AuthContextState>({});

export const AuthContextProvider: React.FC<AuthContextValue> = ({
    children,
    omitPaths = [],
    webSource = "INDIES",
    authGuardedPaths,
    isLoggedIn,
    smsOptInDefault,
    ...rest
}) => {
    return (
        <AuthRouter>
            <InternalAuthContextProvider
                omitPaths={omitPaths}
                webSource={webSource}
                isLoggedIn={isLoggedIn}
                authGuardedPaths={authGuardedPaths}
                smsOptInDefault={smsOptInDefault}
            >
                <AuthContext.Provider value={rest}>
                    {children}
                </AuthContext.Provider>
            </InternalAuthContextProvider>
        </AuthRouter>
    );
};

export const useAuth = () => {
    const props = useContext(AuthContext);
    const {
        setView,
        setWebSource,
        isLoggedIn,
        signout,
        customer,
        loadingCustomer,
        refetchCustomer,
        webSource,
    } = useInternalAuth();

    return {
        ...props,
        setView,
        webSource,
        /** use this method to set the webSource in checkout for example where the webSource can change based on the departure type */
        setWebSource,
        isLoggedIn,
        signout,
        customer,
        loadingCustomer,
        /**
         * @deprecated Should not be used in new code outside of InternalAuthContextProvider.
         * Existing usage in wishlist should be refactored to use own query.
         * WWR-1520
         */
        refetchCustomer,
    };
};
