import { useEffect, useRef } from "react";
import { usePrevious } from "./use-previous";

export const useBroadcastChannel = (isLoggedIn: boolean) => {
    const channelRef = useRef<BroadcastChannel | null>();
    const prevIsLoggedIn = usePrevious(!!isLoggedIn);

    useEffect(() => {
        if (typeof BroadcastChannel !== "undefined") {
            channelRef.current = new BroadcastChannel("login-status");
            channelRef.current.onmessage = () => {
                window.location.reload();
            };
        }
    }, []);

    useEffect(() => {
        if (prevIsLoggedIn !== isLoggedIn) {
            channelRef.current?.postMessage("LOGIN_CHANGED");
        }
    }, [isLoggedIn, prevIsLoggedIn]);

    useEffect(() => () => channelRef.current?.close(), []);
};
