import classnames from "clsx";
import type { ComponentPropsWithoutRef } from "react";
import { HTMLAttributes, ReactNode } from "react";
import styles from "./Text.module.scss";

type ReactComponent = Exclude<React.ElementType, keyof JSX.IntrinsicElements>;

type ValidTags =
    | "p"
    | "span"
    | "a"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "div"
    | "td"
    | "th"
    | "span"
    | "figcaption"
    | "header"
    | "caption"
    | "li"
    | "address"
    | "dd"
    | "dt"
    | "dl"
    | "ul"
    | ReactComponent;

type CustomTagProps<T extends ValidTags> = {
    /***
     * Sets the HTML tag. Defaults to div.
     */
    tag?: T | ValidTags;
    className?: string;
    children?: ReactNode;
    elementStyle?: string;
    /***
     * Sets the font weight. Use none to remove emphasis. Defaults to none. Body and Data only.
     */
    emphasis?: "none" | "1" | "2";
    /***
     * Sets the variant. Defaults to 1. Body, Data, DisplayBody (2), and Detail (3) only.
     */
    variant?: "1" | "2" | "3";
    /***
     * Sets the heading order. Defaults to 1. Heading(6) and DisplayHeading(4) only.
     */
    order?: "1" | "2" | "3" | "4" | "5" | "6";
} & (ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLOrSVGElement>);

type CustomTagType = typeof CustomTag;
type SubComponents = {
    Body: CustomTagType & {
        displayName?: string;
    };
    Detail: CustomTagType & {
        displayName?: string;
    };
    Data: CustomTagType & {
        displayName?: string;
    };
    Heading: CustomTagType & {
        displayName?: string;
    };
    DisplayHeading: CustomTagType & {
        displayName?: string;
    };
    DisplayBody: CustomTagType & {
        displayName?: string;
    };
};

export const Text: CustomTagType & SubComponents = (props) => (
    <CustomTag
        tag={props.tag ?? "p"}
        elementStyle={styles["style--body-1"]}
        {...props}
    />
);
Text.Body = (props) => (
    <CustomTag
        elementStyle={styles[`style--body-${props.variant ?? "1"}`]}
        tag={props.tag ?? "p"}
        {...props}
    />
);
Text.DisplayBody = (props) => (
    <CustomTag
        tag={props.tag ?? "p"}
        elementStyle={styles[`style--display-body-${props.variant ?? "1"}`]}
        {...props}
    />
);
Text.Heading = (props) => (
    <CustomTag
        tag={props.tag ?? `h${props.order ?? "1"}`}
        elementStyle={styles[`style--heading-${props.order ?? "1"}`]}
        {...props}
    />
);
Text.DisplayHeading = (props) => (
    <CustomTag
        elementStyle={styles[`style--display-heading-${props.order ?? "1"}`]}
        tag={props.tag ?? "a"}
        {...props}
    />
);
Text.Detail = (props) => (
    <CustomTag
        tag={props.tag ?? "div"}
        elementStyle={styles[`style--detail-${props.variant ?? "1"}`]}
        {...props}
    />
);
Text.Data = (props) => (
    <CustomTag
        tag={props.tag ?? "div"}
        elementStyle={styles[`style--data-${props.variant ?? "1"}`]}
        {...props}
    />
);
Text.Body.displayName = "Body";
Text.DisplayBody.displayName = "DisplayBody";
Text.Heading.displayName = "Heading";
Text.DisplayHeading.displayName = "DisplayHeading";
Text.Detail.displayName = "Detail";
Text.Data.displayName = "Data";

const DEFAULT_TAG = "div" as const;

export const CustomTag = <T extends ValidTags = typeof DEFAULT_TAG>({
    tag = DEFAULT_TAG,
    emphasis,
    className: cn,
    elementStyle,
    ...rest
}: CustomTagProps<T>): JSX.Element => {
    const Tag: ValidTags = tag;
    const className = classnames(
        cn,
        elementStyle,
        styles.text,
        { [styles["emphasis--1"]]: emphasis === "1" },
        { [styles["emphasis--2"]]: emphasis === "2" },
        { [styles["emphasis--none"]]: emphasis === "none" },
    );
    return (
        <Tag {...rest} className={className}>
            {rest.children}
        </Tag>
    );
};
