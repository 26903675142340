export enum AuthPaths {
    CreateAccount = "/create-account",
    Login = "/login-with-password",
    SetPassword = "/set-password",
    SetPasswordSuccess = "/set-password-success",
    SendMagicLink = "/send-magic-link",
    LoginSuccess = "/login-success",
    ResetPassword = "/reset-password",
    LoginAccountExists = "/login-account-exists",
    VerifyEmail = "/",
    VerifyEmailWishlist = "/verify-email-wishlist",
    VerifyEmailCheckout = "/verify-email-checkout",
    VerifyEmailSavedTrip = "/verify-email-saved-trip",
    LoginWithMagicLinkSuccess = "/login-with-magic-link-success",
    ResetPasswordLinkExpired = "/reset-password-link-expired",
    MagicLinkExpired = "/magic-link-expired",
    SendResetPassword = "/send-reset-password",
    AuthPaths = "AuthPaths",
}
