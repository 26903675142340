import React from "react";

type Props = {
    /**
     * GTM account id
     */
    gtmId: string;
};

/**
 * Loads the GTM noscript iframe
 */
export const GTMNoScript: React.FC<Props> = ({ gtmId }) => {
    return (
        <noscript
            dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
        />
    );
};
